import { createRouter, createWebHistory } from 'vue-router';
import FullScreenLayoutVue from '@/components/layout/FullScreenLayout.vue';
import GnbLayoutVue from '@/components/layout/GnbLayout.vue';
import { Routes } from '@/constants/routes';
import PrivateLayout from '@/components/layout/PrivateLayout.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import NetworkOfflineView from '@/views/NetworkOfflineView.vue';

const importErrorHandler = () => () => window.location.reload();

const routes = [
  { path: Routes.ROOT, component: () => import('@/views/LandingView.vue') },
  {
    path: Routes.FULLSCREEN,
    component: FullScreenLayoutVue,
    children: [
      { path: '', redirect: { name: Routes.NOTFOUND } },
      { path: Routes.LOGIN, name: Routes.LOGIN, component: () => import('@/views/LoginView.vue').catch(importErrorHandler) },
      { path: Routes.AUTHORIZED, name: Routes.AUTHORIZED, component: () => import('@/views/AuthorizedView.vue').catch(importErrorHandler) },
      { path: Routes.NOTFOUND, name: Routes.NOTFOUND, component: NotFoundView },
      { path: Routes.NETWORK_OFFLINE, name: Routes.NETWORK_OFFLINE, component: NetworkOfflineView },
    ],
  },
  //
  {
    path: Routes.GNB,
    component: GnbLayoutVue,
    children: [
      { path: '', redirect: { name: Routes.NOTFOUND } },
      {
        path: Routes.RESOURCE,
        name: Routes.RESOURCE,
        component: () => import('@/views/ResourceView.vue').catch(importErrorHandler),
      },
      {
        path: Routes.MONITORING,
        children: [
          { path: '', redirect: { name: Routes.NOTFOUND } },
          {
            path: Routes.MONITORING__GROUP,
            name: Routes.MONITORING__GROUP,
            component: () => import('@/views/monitoring/GroupView.vue').catch(importErrorHandler),
          },
          {
            path: Routes.MONITORING__RESOURCE,
            name: Routes.MONITORING__RESOURCE,
            component: () => import('@/views/monitoring/ResourceView.vue').catch(importErrorHandler),
          },
        ],
      },
      { path: Routes.CONTROL, name: Routes.CONTROL, component: () => import('@/views/ControlView.vue').catch(importErrorHandler) },
      { path: Routes.SIMULATION, name: Routes.SIMULATION, component: () => import('@/views/SimulationView.vue').catch(importErrorHandler) },
      {
        path: Routes.BIDDING,
        children: [
          { path: '', redirect: { name: Routes.NOTFOUND } },
          {
            path: Routes.BIDDING__DAY_AHEAD,
            name: Routes.BIDDING__DAY_AHEAD,
            component: () => import('@/views/bidding/DayAheadView.vue').catch(importErrorHandler),
          },
          {
            path: Routes.BIDDING__REALTIME,
            name: Routes.BIDDING__REALTIME,
            component: () => import('@/views/bidding/RealtimeView.vue').catch(importErrorHandler),
          },
          {
            path: Routes.BIDDING__MANAGE_PARTICIPATING_CAPACITY,
            name: Routes.BIDDING__MANAGE_PARTICIPATING_CAPACITY,
            component: () => import('@/views/bidding/ManageParticipatingCapacityView.vue').catch(importErrorHandler),
          },
        ],
      },
      {
        path: Routes.SEND,
        name: Routes.SEND,
        component: () => import('@/views/settlement/SendView.vue').catch(importErrorHandler),
      },
    ],
  },
  {
    path: Routes.PRIVATE,
    component: PrivateLayout,
    children: [
      { path: '', redirect: { name: Routes.NOTFOUND } },
      {
        path: Routes.P_METER_DATA,
        name: Routes.P_METER_DATA,
        component: () => import('@/views/private/MeterData.vue').catch(importErrorHandler),
      },
      {
        path: Routes.SETTLEMENT__PDF,
        name: Routes.SETTLEMENT__PDF,
        component: () => import('@/views/settlement/SettlementPdf.vue').catch(importErrorHandler),
        meta: {
          title: '최종정산내역서',
        },
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: { name: Routes.NOTFOUND } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  window.scrollTo(window.scrollX, 0);

  if (to.meta.title) {
    document.title = to.meta.title.toString();
  }
  next();
});

export { router };
