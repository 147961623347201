import { Subject } from "rxjs";

export class AutoReloader {
  readonly $ = new Subject<''>();

  private registration: ServiceWorkerRegistration | null = null;

  constructor() {
    const $ = this.$;
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        let inited = false;
        navigator.serviceWorker.register('/version.js')
          .then((registration) => {
            this.registration = registration;
            let intervalId: any = null;
            registration.onupdatefound = () => {
              intervalId && clearInterval(intervalId);
              intervalId = setInterval(() => {
                registration.update();
              }, 1000 * 60);
    
              const newWorker = registration.installing;
              if (newWorker) {
                newWorker.onstatechange = () => {
                  if (newWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                      if (inited) {
                        console.log('New content is available; please refresh.');
                        $.next('');
                      }
                      inited = true;
                    }
                  }
                };
              }
            };
          })
          .catch((error) => console.error('AutoReloader ::: ', error));
      });
    }
  }

  update() {
    this.registration?.update();
  }
}