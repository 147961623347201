<script setup lang="ts">
  import {
    defineComponent,
    reactive,
    ref,
    computed,
    onMounted,
    onUpdated,
    watch,
    nextTick,
  } from 'vue';
  import { Routes } from '@/constants/routes';
  import { t } from '@/constants/text';
  import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ThunderboltOutlined,
    ControlOutlined,
    GroupOutlined,
    AppstoreOutlined,
    SmileOutlined,
    DollarOutlined,
  } from '@ant-design/icons-vue';

  import { useAuthPage } from '@/hooks/useAuthPage';
  import { useRoute, useRouter } from 'vue-router';
  import { ValueOf } from '@/utils/types';
  import MenuLogo from '@/components/icons/MenuLogo.vue';
  import { useCurrentTimeStore } from '@/store/useCurrentTimeStore';
  import { storeToRefs } from 'pinia';
  import moment from 'moment';
  import { s } from '@/utils/style';
  import { useUserInfo } from '@/hooks/useUserInfo';
  import { useAlertStore } from '@/store/useAlertStore';
  import { getSectionItem } from '@/utils/getter';
  import PwaInstallAlert from '../PwaInstallAlert.vue';
import { useAutoReload } from '@/hooks/useAutoReload';

  const router = useRouter();
  const route = useRoute();
  const currentTimeStore = useCurrentTimeStore();

  const { userInfo } = useUserInfo();
  const { currentTime } = storeToRefs(currentTimeStore);

  const scrollData = reactive({
    height: 0,
    trackHeight: 0,
    top: 0,
  });

  const setScroll = () => {
    const scrollLocation = document.documentElement.scrollTop;
    const docHeight = document.documentElement.clientHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    const ratio = Math.floor((docHeight / scrollHeight) * 100) / 100;
    scrollData.height = docHeight;
    const trackHeight = docHeight * ratio * 1;
    scrollData.trackHeight =
      trackHeight < scrollData.height ? docHeight * ratio * 1 : 0;
    scrollData.top = scrollLocation * ratio * 1;
  };

  const scrollbarTrack = ref<HTMLDivElement | null>(null);
  const isControlTrackMode = ref(false);

  onMounted(() => {
    setScroll();
    window.addEventListener('scroll', () => {
      setScroll();
    });

    const target = window.document.getElementById('app')!;
    new ResizeObserver(() => {
      nextTick(() => {
        setScroll();
      });
    }).observe(target);

    scrollbarTrack.value?.addEventListener('mousedown', () => {
      isControlTrackMode.value = true;
    });
    window?.addEventListener('mouseup', () => {
      isControlTrackMode.value = false;
    });

    window.addEventListener('mousemove', (e) => {
      if (isControlTrackMode.value) {
        window.scrollBy({
          top: e.movementY * document.documentElement.scrollHeight * 0.001, // 곱하는 값은 감도 설정값 입니다. 높을수록 민감하게 움직입니다.
        });
      }
    });
  });

  onUpdated(() => {
    setScroll();
  });

  const curSectionItem = computed(() => getSectionItem(currentTime.value));
  const collapsed = ref<boolean>(localStorage.getItem('collapsed') === 'true');

  watch(collapsed, (cur) => {
    localStorage.setItem('collapsed', `${cur}`);
  });

  const selectedKeys = computed(() => {
    return route.path
      .split('/')
      .slice(1)
      .reduce<string[]>((a, c) => {
        a.push((a[a.length - 1] ?? '') + '/' + c);
        return a;
      }, []);
  });

  const openedKeys = ref([location.pathname.split('/').slice(0, 3).join('/')]);

  const { authorized, logout } = useAuthPage();

  const { isRebuilded } = useAutoReload();

  const onClickMenuItem = (route: ValueOf<typeof Routes>) => {
    if (isRebuilded.value) {
      window.location.href = route;
    } else {
      router.push({
        path: route,
      });
    }
  };

  const menus: {
    route: ValueOf<typeof Routes>;
    icon?: ReturnType<typeof defineComponent>;
    meta?: {
      visibleAlertIcon?: boolean;
    };
    children?: {
      route: ValueOf<typeof Routes>;
      icon?: ReturnType<typeof defineComponent>;
    }[];
  }[] = [
    {
      route: Routes.RESOURCE,
      icon: AppstoreOutlined,
    },
    {
      route: Routes.BIDDING,
      icon: ThunderboltOutlined,
      children: [
        {
          route: Routes.BIDDING__DAY_AHEAD,
        },
        {
          route: Routes.BIDDING__REALTIME,
        },
        {
          route: Routes.BIDDING__MANAGE_PARTICIPATING_CAPACITY,
        },
      ],
    },
    {
      route: Routes.MONITORING,
      icon: GroupOutlined,
      children: [
        {
          route: Routes.MONITORING__GROUP,
        },
        {
          route: Routes.MONITORING__RESOURCE,
        },
      ],
    },
    {
      route: Routes.CONTROL,
      icon: ControlOutlined,
      meta: {
        visibleAlertIcon: true,
      },
    },
    {
      route: Routes.SEND,
      icon: DollarOutlined,
    },
  ];

  const alertStore = useAlertStore();
  const { isExistAlert } = storeToRefs(alertStore);

  const onMouseEnter = () => {
    window.document.body.style.overflow = 'hidden';
    const fakeScroll = window.document.getElementById('fake-scroll');
    if (fakeScroll) {
      fakeScroll.style.display = 'block';
    }
  };
  const onMouseLeave = () => {
    window.document.body.style.overflow = 'auto';
    const fakeScroll = window.document.getElementById('fake-scroll');
    if (fakeScroll) {
      fakeScroll.style.display = 'none';
    }
  };

  watch(route, () => {
    onMouseLeave();
  });
</script>

<template>
  <PwaInstallAlert />
  <a-layout v-if="authorized" class="gnb__layout-wrapper">
    <a-layout-sider
      v-model:collapsed="collapsed"
      theme="light"
      :width="220"
      style="border-right: 1px solid var(--colorBorderSecondary)"
    >
      <div
        class="py-4"
        :class="{
          'mx-4': collapsed,
          'mx-6': !collapsed,
        }"
        :style="s().p('sticky').t(0).zIndex('HEADER').bg('white').v()"
      >
        <div
          class="d-flex align-center w-full"
          :class="{
            'justify-between': !collapsed,
            'justify-center': collapsed,
          }"
        >
          <div
            class="gnb__layout-sidebar__logo"
            :class="{ 'd-none': collapsed }"
          >
            <MenuLogo />
          </div>
          <div>
            <a-button
              type="text"
              class="pa-0"
              @click="() => (collapsed = !collapsed)"
            >
              <MenuUnfoldOutlined
                v-if="collapsed"
                style="font-size: 20px; color: var(--colorIcon)"
              />
              <MenuFoldOutlined
                v-else
                style="font-size: 20px; color: var(--colorIcon)"
              />
            </a-button>
          </div>
        </div>
      </div>
      <a-menu
        :selectedKeys="selectedKeys"
        :openKeys="openedKeys"
        mode="inline"
        class="popup-wrapper"
        style="
          position: sticky;
          top: calc(24px + 52px);
          border-right: 0;
          z-index: 1052;
        "
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <template v-for="menu in menus" :key="menu.route">
          <a-sub-menu
            v-if="menu.children"
            :key="menu.children ? menu.route : ''"
            class="my-2"
          >
            <template #title>
              <component
                v-if="menu.icon"
                :is="menu.icon"
                :style="{
                  'font-size': '20px',
                  'font-weight': 700,
                  transform: collapsed ? 'translateX(-2px)' : '',
                }"
              />
              <span :key="menu.route" style="font-size: 16px; font-weight: 700">
                {{ t(menu.route) }}
              </span>
            </template>
            <a-menu-item
              v-for="submenu in menu.children"
              :key="submenu.route"
              @click="onClickMenuItem(submenu.route)"
              @mouseenter="onMouseEnter"
              @mouseleave="onMouseLeave"
            >
              <span
                :key="submenu.route"
                style="font-size: 16px; font-weight: 700"
              >
                {{ t(submenu.route) }}
              </span>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item
            v-else
            :key="menu.route"
            @click="onClickMenuItem(menu.route)"
            class="my-2"
            :title="t(menu.route)"
          >
            <a-badge
              v-if="collapsed && menu.meta?.visibleAlertIcon && isExistAlert"
              dot
              :style="s().p('absolute').t(6).r(13).zIndex(999).v()"
            ></a-badge>
            <component
              v-if="menu.icon"
              :is="menu.icon"
              :style="{
                'font-size': '20px',
                'font-weight': 700,
                transform: collapsed ? 'translateX(-2px)' : '',
              }"
            ></component>
            <span style="font-size: 16px; font-weight: 700">
              {{ t(menu.route) }}
              <a-badge
                v-if="!collapsed && menu.meta?.visibleAlertIcon && isExistAlert"
                dot
                status="error"
                class="ml-1"
              />
            </span>
          </a-menu-item>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        theme="light"
        class="bg-white d-flex align-center justify-between py-0 px-13"
        :style="s().p('sticky').t(0).zIndex('HEADER').v()"
      >
        <div>
          <a-typography-title class="d-inline-block my-4 mx-2" :level="4">
            <!-- title -->
            {{ t(selectedKeys[selectedKeys.length - 1]) }}
          </a-typography-title>
        </div>
        <div>
          <div class="d-flex align-center justify-between h-full">
            <div
              class="d-flex align-center justify-between h-full text-ellipsis"
            >
              <a-typography-text ellipsis class="text-xl bold">
                {{
                  moment(currentTime).format('YYYY년 MM월 DD일(dd) HH:mm:ss')
                }}
              </a-typography-text>
              <a-typography-text
                ellipsis
                class="ml-2 mr-4 text-xl bold text-primary"
              >
                거래시간 {{ curSectionItem[0] }}시 {{ curSectionItem[1] }}구간
              </a-typography-text>
            </div>
            <div class="d-flex align-center gap-4">
              <div>
                <SmileOutlined size="20" class="text-primary" />
                <a-typography-text class="ml-1">
                  {{ userInfo?.name }}님
                </a-typography-text>
              </div>
              <!-- <a-popconfirm title="로그아웃 하시겠어요?" @confirm="onClickLogoutBtn" placement="bottomRight"> -->
              <a-button
                class="float-right"
                type="primary"
                ghost
                @click="logout"
              >
                로그아웃
              </a-button>
              <!-- </a-popconfirm> -->
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-layout-content class="mx-0">
        <!-- <a-breadcrumb style="height: 32px;"> -->
        <!-- <a-breadcrumb-item v-for="key in selectedKeys.slice(1)" :key="key">{{ t(key) }}</a-breadcrumb-item> -->
        <!-- </a-breadcrumb> -->
        <div
          class="gnb__layout-content__wrapper"
          :class="{ 'bg-white': false }"
        >
          <div class="gnb__layout-content mx-13">
            <router-view></router-view>
          </div>
        </div>
      </a-layout-content>
      <!-- <a-layout-footer style="text-align: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
  <Teleport to="#customScrollContainer">
    <div v-show="isControlTrackMode" class="custom-scroll-mask"></div>
    <div
      style="
        float: right;
        border: 1.25px solid rgb(232, 232, 232);
        width: 16.5px;
      "
    >
      <div
        :style="
          s()
            .p('fixed')
            .t(0)
            .r(0)
            .zIndex('GNB_SCROLLBAR')
            .bg('#fafafa')
            .pa(3)
            .w(16.5)
            .h(scrollData.height)
            .v()
        "
      >
        <div
          ref="scrollbarTrack"
          :style="
            s()
              .p('relative')
              .t(scrollData.top)
              .r(0)
              .zIndex('GNB_SCROLLBAR_TRACK')
              .bg('rgb(193,193,193)')
              .h(scrollData.trackHeight)
              .w(10)
              .round(20)
              .v()
          "
        ></div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.gnb__layout {
  &-wrapper {
    min-height: 100vh;
    /* min-width: 1280px; */
    /* min-width: 1360px; */
    min-width: 1440px;
  }
    &-content__wrapper {
      /* overflow: scroll; */
      $header-height: 64px;
      $breadcrumb-height: 22px;
      /* height: calc(100vh - ($header-height + $breadcrumb-height + 40px)); */
    }
  }

  .custom-scroll-mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 99999;
  }
</style>
