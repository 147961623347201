import { AutoReloader } from "@/utils/classes/AutoReloader";
import { Subscription } from "rxjs";
import { inject, onMounted, onUnmounted, ref } from "vue"

export const useAutoReload = () => {
  const autoReloader = inject<AutoReloader>('autoReloader');

  const subscription = ref<Subscription | null>(null);

  const isRebuilded = ref(false);

  onMounted(() => {
    subscription.value = autoReloader?.$.subscribe(() => {
      isRebuilded.value = true;
    }) ?? null;
  });

  onUnmounted(() => {
    subscription.value?.unsubscribe();
  });

  return {
    isRebuilded,
    update: autoReloader?.update ?? (() => {}),
  };
}